import React, { useEffect, useState, useRef } from "react";
import { CheckCircle, Ban } from "lucide-react";
import { confirmPayment } from "../utils/paynow.js";
import QRCode from "react-qr-code";
import html2canvas from "html2canvas";
import { createPaymentRecords, fetchTicketOrders } from "../utils/auth.js";
import Loader from "./Loader"; // Import the Loader component
import sendEmail from "../utils/mailer.js";

const PaymentSuccessPageWeb = () => {
  const [paymentRecord, setPaymentRecord] = useState(null);
  const [success, setSucess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [hasChecked, setHasChecked] = useState(false);
  const [value, setValue] = useState();
  const [back, setBack] = useState("#FFFFFF");
  const [fore, setFore] = useState("#000000");
  const [size, setSize] = useState(256); 
  const initialized = useRef(false);

  const check = async (poll_url, storedRecord) => {
   

    try {
      let result;
      const user = JSON.parse(localStorage.getItem("User"));          
      if (!initialized.current) {
        initialized.current = true;        
        result = await confirmPayment(poll_url);
      }
      const response = result.response;
      
      if (response.success === true) {
        await createPaymentRecords(storedRecord);
        const data = await fetchTicketOrders(
          storedRecord.user_id,
          storedRecord.ticket_id
        );        
        setSucess(true);        
        setValue(data[0]?.hash);
        const email = await sendEmail(user.email,data[0]?.hash)
        localStorage.removeItem("paymentRecord"); 
      } else {
        setSucess(false);
      }
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    setLoading(true);
    const storedRecord = JSON.parse(localStorage.getItem("paymentRecord"));
    setPaymentRecord(storedRecord);
    
    const checkPayment = async () => {
      await check(storedRecord.poll_url, storedRecord);
      setLoading(false);
    };

    checkPayment();

    return () => {};
  }, []);

  if (loading) return <Loader />; 

  const downloadQRCode = async () => {
    const qrCodeElement = document.getElementById("qr-code");
    const canvas = await html2canvas(qrCodeElement);
    const dataURL = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.href = dataURL;
    link.download = "E-ticket.png";
    link.click();
    setIsDownloaded(true);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      {success && (
        <>
          <CheckCircle className="w-24 h-24 mb-6 text-green-500" />

          <h1 className="mb-2 text-2xl font-semibold text-center text-gray-800">
            Ticket Purchase Success!
          </h1>
          <p className="text-center text-gray-600">Thank you for using Tango</p>

          {value && (
            <>
              <div id="qr-code">
                <QRCode
                  title="Tango Ticket"
                  value={value}
                  bgColor={back}
                  fgColor={fore}
                  size={size === "" ? 0 : size}
                />
              </div>
              {!isDownloaded && (
                <button
                  onClick={downloadQRCode}
                  className="mt-4 p-2 bg-blue-500 text-white rounded"
                >
                  Download QR Code
                </button>
              )}
            </>
          )}
        </>
      )}
      {!success && (
        <>
          <Ban className="w-24 h-24 mb-6 text-red-500" />

          <h1 className="mb-2 text-2xl font-semibold text-center text-gray-800">
            Ticket Purchase Unsuccessful!
          </h1>
          <p className="text-center text-gray-600">Please Try Again</p>
        </>
      )}
    </div>
  );
};

export default PaymentSuccessPageWeb;
