import {supabase} from '../api.js'
export async function signInWithEmail(email) {
    try {
        const { error } = await supabase.auth.signInWithOtp({ email })
        if (error) throw error
        console.log('OTP sent to email!')
      } catch (error) {
        console.error('Error during sign in:', error)
      }
  }

  export const checkSession=async ()=>{
    const {data:{session},}= await supabase.auth.getSession()
    return session
  }

  export const verifyOtp=async(email,token)=>{
    const {
        data: { session },
        error,
      } = await supabase.auth.verifyOtp({
        email,
        token: token,
        type: 'email',
      })
      if(error)
      {throw error}
      
      return session
      
  }


  export async function createEventCustomAnswer(userId, eventId, answers) {
    const formattedAnswers = answers.map((answer) => ({      
      created_at: new Date().toISOString(),
      answer: Array.isArray(answer.answer) ? answer.answer.join(', ') : answer.answer,
      event_id: eventId,
      question_id: answer.questionId.replace("*email", "").trim(),
      user_id: userId
    }));
    console.log('formarted',formattedAnswers)
    const { data, error } = await supabase
      .from('event_custom_answers')
      .insert(formattedAnswers);
      
  
    if (error) {
      console.error('Error inserting data:', error);
    } else {
      console.log('Inserted records:', data);
    }
  }

  export async function createPaymentRecords(record) {  
    const { data, error } = await supabase
      .from('event_ticket_payments')
      .insert(record);        
    if (error) {
      console.error('Error inserting data:', error);
    } else {
      console.log('Inserted records:', data);
    }
    return data
  }

  export async function fetchTicketOrders(userId,ticketId) {
    
    
    const { data, error } = await supabase
      .from('ticket_orders')
      .select("*")
      .eq("user_id", userId) 
      .eq("ticket_id", ticketId) 
    if (error) {
      console.error('Error inserting data:', error);
    } else {
      console.log('Inserted records:', data);
      return data
    }
  }