import { Ticket } from "lucide-react";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { signInWithEmail, verifyOtp ,createEventCustomAnswer} from "../utils/auth.js";
import { supabase } from "../api.js";
import { MakePayment } from "../utils/paynow.js";

export default function PaymentForm({ onPayment, tickets, questions, event }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [isOtp, setIsOtp] = useState(false);
  const [otp, setOtp] = useState(""); 
  console.log(questions);
  const [loading, setLoading] = useState(false);
  const [session, setSession] = useState(null);
  const [check,setCheck]= useState(true)
  const [numberOfTickets, setNumberOfTickets] = useState(1); // New state for number of tickets

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const fetchSession = async () => {
    try {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      console.log("session", session);
      setSession(session?.user ?? null);
    } catch (error) {
      console.log(error);
    }
  };  
  console.log(tickets);
  const totalPrice = tickets?.price * tickets?.service_fee + tickets?.price;
  console.log(totalPrice);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = answers.find(
        (item) => item.questionId.includes("email") // Updated to check for inclusion

      )?.answer;
      console.log(email)
    if (answers.find(
        (item) => item.questionId.includes("email") // Updated to check for inclusion

      )?.answer) {
      setLoading(true);

      try {
        const login = await signInWithEmail(email);
        setIsOtp(true);
        console.log("login", login);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }   
  };
  const handleCheckboxChange = (questionId, isChecked, value) => {
    setAnswers((prevAnswers) => {
      const existingAnswer = prevAnswers.find(
        (answer) => answer.questionId === questionId
      );

      if (existingAnswer) {
        const updatedValues = existingAnswer.answer || [];
        const newValues = isChecked
          ? [...updatedValues, value]
          : updatedValues.filter((v) => v !== value);
        return prevAnswers.map((answer) =>
          answer.questionId === questionId
            ? { ...answer, answer: newValues }
            : answer
        );
      } else {
        return [...prevAnswers, { questionId, answer: [value] ,type:'checkbox'}];
      }
    });
  };

  const handleInputChange = (questionId, value) => {
    setAnswers((prevAnswers) => {
      const existingAnswerIndex = prevAnswers.findIndex(
        (answer) => answer.questionId === questionId
      );

      if (existingAnswerIndex !== -1) {
        const updatedAnswers = [...prevAnswers];
        updatedAnswers[existingAnswerIndex].answer = value;
        return updatedAnswers;
      } else {
        return [...prevAnswers, { questionId, answer: value }];
      }
    });
  };
  console.log(answers)
  
  const handleSubmitFinal= async()=>{
    setLoading(true)
    const user = JSON.parse(localStorage.getItem('User'));
    const email = answers.find(
        (item) => item.questionId.includes("email") // Updated to check for inclusion

      )?.answer;
    
    if(questions&&questions?.length>0){
        const record=await createEventCustomAnswer(user.id,event.id,answers)
        const data = await MakePayment(tickets.name,email,totalPrice)
       
       const paymentRecord = {
       
        created_at: new Date().toISOString(),
        user_id: user.id,
        event_id: event.id,
        ticket_id: tickets.id,
        poll_url: data.pollUrl,
        use_points: false,
        ticket_price: tickets?.price*numberOfTickets,
        admission_count: numberOfTickets,
        payment_success: true,
        service_fee_amount: tickets?.price * tickets?.service_fee*numberOfTickets,
        payment_summary_url: '',
      };
      localStorage.setItem('paymentRecord', JSON.stringify(paymentRecord));
      setLoading(false)
        if (data.redirectUrl) {
            window.location.href = data.redirectUrl;
        }
    }else{
       const data = await MakePayment(tickets.name,email,totalPrice)
       const paymentRecord = {
       
        created_at: new Date().toISOString(),
        user_id: user.id,
        event_id: event.id,
        ticket_id: tickets.id,
        poll_url: data.pollUrl,
        use_points: false,
        ticket_price: tickets?.price*numberOfTickets,
        admission_count: 1,
        payment_success: true,
        service_fee_amount: tickets?.price * tickets?.service_fee*numberOfTickets,
        payment_summary_url: '',
      };
      localStorage.setItem('paymentRecord', JSON.stringify(paymentRecord));
      setLoading(false)
        if (data.redirectUrl) {
            window.location.href = data.redirectUrl;
        }
    }
  }



  const handleSubmitOtp = async (e) => {
    e.preventDefault();
    if (otp) {
      try {
        setLoading(true)
        const email = answers.find(
            (item) => item.questionId.includes("email") // Updated to check for inclusion
  
          )?.answer;
          setAnswers((prevAnswers) => 
            prevAnswers.map((answer) => ({
              ...answer,
              questionId: answer.questionId.replace("*email", "").trim() // Remove the word "email"
            }))
          );

        

        const response = await verifyOtp(email, otp);
        console.log("OTP Response:", response.user.id);
        localStorage.setItem('User',JSON.stringify(response.user))
        setCheck(false)
       
        setLoading(false)
      } catch (error) {
        console.error("Error verifying OTP:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  

 

  return (
    <div>
      <button
        onClick={
          isMobile
            ? () => (window.location.href = `${event.event_url}`)
            : toggleModal
        }
        className="block w-full py-3 mt-4 text-center text-white bg-[#147481] rounded hover:bg-white-600"
      >
        Purchase Ticket
      </button>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 p-4">
          <div className="bg-white p-10 rounded-xl shadow-lg max-w-3xl w-full overflow-auto max-h-[80vh] overflow-y-auto">
           {!isOtp&&check&&<h2 className="text-xl font-bold mb-6 text-center">Payment Form</h2>}
            {isOtp&&check&&<h4 className="text-xl font-bold mb-6 text-center">
              Enter your Otp Code
            </h4>}
            {loading&&
             <div className="flex items-center justify-center ">
             <div className="w-16 h-16 border-4 border-[#147481] rounded-full border-t-transparent animate-spin"></div>
           </div>
            }
           
            {!isOtp &&check&& (
              <form onSubmit={handleSubmit} className="space-y-6 overflow-auto">
                {questions && questions.length > 0 ? (
                  questions.map((question) => (
                    <div key={question.id} className="mb-4">
                      <label className="block text-sm font-medium mb-2">
                        {question.question}{" "}
                        {question.is_required && (
                          <span className="text-red-500">*</span>
                        )}
                      </label>

                      {/* Text Input */}
                      {question.answer_type.toLowerCase() === "text" && (
                        <input
                          type="text"
                          name={question.id}
                          placeholder={`Enter ${question.question}`}
                          required={question.is_required}
                          className="border p-2 w-full rounded"
                          onChange={(e) =>
                            handleInputChange(question.id, e.target.value)
                          }
                        />
                      )}

                      {/* Email Input */}
                      {question.answer_type.toLowerCase() === "email" && (
                        <input
                          type="email"
                          name={question.id}
                          placeholder={`Enter ${question.question}`}
                          required={question.is_required}
                          className="border p-2 w-full rounded"
                          onChange={(e) =>
                            handleInputChange(question.id+'*'+'email', e.target.value)
                          }
                        />
                      )}

                      {/* Number Input */}
                      {question.answer_type.toLowerCase() === "number" && (
                        <input
                          type="number"
                          name={question.id}
                          placeholder={`Enter ${question.question}`}
                          required={question.is_required}
                          className="border p-2 w-full rounded"
                          onChange={(e) =>
                            handleInputChange(question.id, e.target.value)
                          }
                        />
                      )}
                      {/* Number Input */}
                      {question.answer_type.toLowerCase() === "date" && (
                        <input
                          type="date"
                          name={question.id}
                          placeholder={`Enter ${question.question}`}
                          required={question.is_required}
                          className="border p-2 w-full rounded"
                          onChange={(e) =>
                            handleInputChange(question.id, e.target.value)
                          }
                        />
                      )}


                      {/* Radio Button Input */}
                      {question.answer_type.toLowerCase() === "single_choice" && (
                        <div className="space-y-2">
                            
                          {Object.entries(question.options).map(([key, label]) => (
                            <div key={key} className="flex items-center">
                              <input
                                type="radio"
                                name={question.id}
                                value={label}
                                required={question.is_required}
                                className="mr-2"
                                onChange={(e) =>
                                  handleInputChange(question.id, e.target.value)
                                }
                              />
                              <label>{label}</label>
                            </div>
                          ))}
                        </div>
                      )}

                      {/* Checkbox Input for Multiple Choice */}
                      {question.answer_type.toLowerCase() === "multiple_choice" && (
                        <div className="space-y-2">
                          {Object.entries(question.options).map(([key, label])=> (
                            <div key={key} className="flex items-center">
                              <input
                                type="checkbox"
                                name={question.id}
                                value={label}
                                className="mr-2"
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    question.id,
                                    e.target.checked,
                                    label
                                  )
                                }
                              />
                              <label>{label}</label>
                            </div>
                          ))}
                        </div>
                      )}

                      {/* Boolean Input */}
                      {question.answer_type.toLowerCase() === "boolean" && (
                        <div className="flex items-center space-x-4">
                          <label className="flex items-center">
                            <input
                              type="radio"
                              name={question.id}
                              value="true"
                              required={question.is_required}
                              className="mr-2"
                              onChange={(e) =>
                                handleInputChange(question.id, e.target.value)
                              }
                            />
                            Yes
                          </label>
                          <label className="flex items-center">
                            <input
                              type="radio"
                              name={question.id}
                              value="false"
                              required={question.is_required}
                              className="mr-2"
                              onChange={(e) =>
                                handleInputChange(question.id, e.target.value)
                              }
                            />
                            No
                          </label>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <div className="mb-4">
                    <label className="block text-sm font-medium mb-2">
                      Email <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="email"
                      placeholder="Enter your email"
                      required
                      className="border p-2 w-full rounded"
                      onChange={(e) =>
                        handleInputChange("email", e.target.value)
                      }
                    />
                  </div>
                )}

             

                {/* Submit and Close Buttons */}
                <div className="flex justify-end gap-4">
                  <button
                    type="submit"
                    className="py-3 px-6 bg-[#147481] text-white rounded hover:bg-white-600 transition"
                  >
                    Submit
                  </button>
                  <button
                    onClick={toggleModal}
                    type="button"
                    className="py-3 px-6 bg-gray-500 text-white rounded hover:bg-gray-700 transition"
                  >
                    Close
                  </button>
                </div>
              </form>
            )}
            {isOtp &&check &&(
              <form onSubmit={handleSubmitOtp} className="space-y-6 overflow-auto">
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">
                    OTP <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter OTP Code sent To Your Email"
                    required
                    className="border p-2 w-full rounded"
                    onChange={(e) => setOtp(e.target.value)}
                  />
                </div>

                <div className="flex justify-end gap-4">
                  <button
                    type="submit"
                    className="py-3 px-6 bg-[#147481] text-white rounded hover:bg-white-600 transition"
                  >
                    Submit
                  </button>
                  <button
                    onClick={toggleModal}
                    type="button"
                    className="py-3 px-6 bg-gray-500 text-white rounded hover:bg-gray-700 transition"
                  >
                    Close
                  </button>
                </div>
              </form>
            )}
            {!check&&<div className="">
            <h4 className="text-xl font-bold mb-6 text-center">
              Check Your Details
            </h4>
                </div>
                }
            {tickets && !check&&(
                  <div className="flex flex-col gap-4">
                    
                      <span>Number of Tickets:</span>
                      <input
                        type="number"
                        min="1"
                        value={numberOfTickets}
                        onChange={(e) => setNumberOfTickets(e.target.value)}
                        className="border p-2 w-full rounded"
                      />
                    

                    <label className="border p-2 w-full rounded">
                      <span>Ticket Price:</span> <span>${tickets?.price* numberOfTickets} </span>
                    </label>

                    <label className="border p-2 w-full rounded">
                      <span>Service Fee Price:</span>{" "}
                      <span>${tickets?.price * tickets?.service_fee* numberOfTickets} </span>
                    </label>

                    <label className="border p-2 w-full rounded">
                      <span>Total Price: </span> <span>${totalPrice * numberOfTickets} </span> {/* Updated to multiply by number of tickets */}
                    </label>
                    <div className="flex justify-end gap-4">
                  <button
                    type="submit"
                    onClick={handleSubmitFinal}
                    className="py-3 px-6 bg-[#147481] text-white rounded hover:bg-white-600 transition"
                  >
                    Submit
                  </button>
                  <button
                    onClick={toggleModal}
                    type="button"
                    className="py-3 px-6 bg-gray-500 text-white rounded hover:bg-gray-700 transition"
                  >
                    Close
                  </button>
                </div>
                  </div>

                )}

        
          </div>
        </div>
      )}
    </div>
  );
}