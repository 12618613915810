import {createClient} from '@supabase/supabase-js';


// Initialize Supabase client
// const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
// const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;

const supabaseUrl = "https://mjnnllnexqytcypedwtg.supabase.co";
const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1qbm5sbG5leHF5dGN5cGVkd3RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTE2MTY4MTUsImV4cCI6MjAyNzE5MjgxNX0.ooZDdzjtfs2mz9ixO-_40G-O_whBBC0spH3sGY8UHLE";

export const supabase = createClient(supabaseUrl, supabaseKey);
